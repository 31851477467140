<template>
  <div>
    <Form ref="registerForm" :model="form" :rules="rules" style="margin-top: 25px">
      <FormItem prop="company" v-if="isCompany">
        <i-input v-model="form.company" :disabled="companyStatus" class="borerB" clearable :maxlength="32" size="large" :placeholder="$t('register_input_company')">
<!--          <span slot="prefix"-->
<!--                style="height:100%;display:flex;align-items:center;justify-content:center">-->
<!--              <Icon type="md-contacts"/>-->
<!--          </span>-->
        </i-input>
      </FormItem>
      <FormItem prop="nickname">
        <i-input v-model="form.nickname" :maxlength="32" class="borerB" clearable size="large" :placeholder="$t('register_input_user')">
<!--          <span slot="prefix"-->
<!--                style="height:100%;display:flex;align-items:center;justify-content:center">-->
<!--              <Icon type="ios-person"></Icon>-->
<!--          </span>-->
        </i-input>
      </FormItem>
      <FormItem prop="mobile">
        <i-input v-model="form.mobile" :maxlength="11" class="borerB" clearable size="large" :placeholder="$t('register_input_mobile')">
<!--          <span slot="prefix"-->
<!--                style="height:100%;display:flex;align-items:center;justify-content:center">-->
<!--            <i class="el-icon-phone"></i>-->
<!--          </span>-->
        </i-input>
      </FormItem>
      <FormItem prop="password">
        <i-input type="password" v-model="form.password" class="borerB" clearable :maxlength="32" size="large" autocomplete="off"
                 :placeholder="$t('register_input_password')">
<!--          <span slot="prefix"-->
<!--                style="height:100%;display:flex;align-items:center;justify-content:center">-->
<!--              <Icon type="ios-key"/>-->
<!--          </span>-->
        </i-input>
        <input type="password" hidden autocomplete="new-password" value="" />
      </FormItem>
      <FormItem prop="password">
        <i-input type="password" v-model="form.againPassword" class="borerB" clearable :maxlength="32" size="large" autocomplete="off"
                 placeholder="请再次输入密码">
          <!--          <span slot="prefix"-->
          <!--                style="height:100%;display:flex;align-items:center;justify-content:center">-->
          <!--              <Icon type="ios-key"/>-->
          <!--          </span>-->
        </i-input>
        <input type="password" hidden autocomplete="new-password" value="" />
      </FormItem>
      <FormItem prop="captcha">
        <Row>
          <i-col span="16">
            <i-input v-model="form.captcha" class="borerB" clearable :maxlength="4" size="large"
                     :placeholder="$t('register_input_captcha')">
<!--              <span slot="prefix">-->
<!--                  <Icon :size="18" type="ios-copy-outline"></Icon>-->
<!--                </span>-->
            </i-input>
          </i-col>
          <i-col span="7" push="1">
            <div @click="getCaptcha" style="height: 36px;text-align:center;border-radius: 4px;cursor:pointer;;">
              <img :src="captchaUrl" alt="" style="display:inline-block;height: 36px">
            </div>
          </i-col>
        </Row>
      </FormItem>
      <FormItem prop="mobileCode">
        <Row>
          <i-col span="16">
            <i-input clearable v-model="form.mobileCode" class="borerB" :maxlength="4" size="large"
                     :placeholder="$t('register_input_mobile_code')">
<!--                <span slot="prefix">-->
<!--                    <i class="el-icon-lock"></i>-->
<!--                </span>-->
            </i-input>
          </i-col>
          <i-col span="7" push="1">
            <div class="get-code" @click="getMobileCode" v-if="mobileCodeStatus">{{ $t('login_get_code') }}</div>
            <div class="get-code-not" v-else>{{sec}}{{$t('login_mobild_code_unit')}}{{ $t('login_mobild_code_again') }}</div>
          </i-col>
        </Row>
      </FormItem>

      <FormItem prop="read" class="read-tip">
        <Row type="flex" justify="space-between">
          <Col>
            <Checkbox v-model="form.read" style="line-height: 16px"  @click.prevent="toAgreement">
              <span style="color:#2d8cf0;cursor:pointer"
                   >授权登录视为您已同意<span>《用户协议及隐私协议》</span></span>
            </Checkbox>
          </Col>
        </Row>
      </FormItem>
      <Button @click="handleSubmit" style="margin-bottom:20px;height:50px;font-size:18px" type="primary" size="large" :loading="loading"
              long>{{ $t('register_input_submit') }}
      </Button>
      <Row type="flex" justify="space-between">
        <Col>
<!--          <div style="display: inline" @click="toRegisterSite" class="message" v-if="!isCompany">注册运营商-->
<!--            <Tooltip >-->
<!--              <Icon type="ios-help-circle-outline" />-->
<!--              <div slot="content">-->
<!--                <p>自己成为一个运营商，可以添加自己的成员。</p>-->
<!--                <p style="white-space: pre-wrap;">具有功能：分发激活，练习模考，智能组件，课后作业，自由题库，统计分析等。</p>-->
<!--              </div>-->
<!--            </Tooltip>-->
<!--          </div>-->
<!--          <div style="display: inline" @click="toRegister" class="message" v-else >注册账号-->
<!--            <Tooltip >-->
<!--              <Icon type="ios-help-circle-outline" />-->
<!--              <div slot="content">-->
<!--                <p>有针对性的专项练习，多种分类多种练习模式。</p>-->
<!--              </div>-->
<!--            </Tooltip>-->
<!--          </div>-->
<!--          <div style="display: inline; padding-left: 20px">邀请链接注册-->
<!--            <Tooltip >-->
<!--              <Icon type="ios-help-circle-outline" />-->
<!--              <div slot="content">-->
<!--                <p>需通过运营商的邀请链接进行注册。</p>-->
<!--                <p>如未有邀请链接，请联系运营商管理员获取。</p>-->
<!--              </div>-->
<!--            </Tooltip>-->
<!--          </div>-->
        </Col>
        <Col>
          <span>{{ $t('register_has_account') }}</span>
          <span style="color:#2d8cf0;cursor:pointer" @click="toLogin">{{ $t('register_to_login') }}</span>
        </Col>
      </Row>
    </Form>
  </div>
</template>

<script>
import Cookies from 'js-cookie';
import util from '@/utils/tools.js';
import {tokenName} from '@/router/init.js'
export default {
  name: "registerForm.vue",
  data() {
    const validateCompany = (rule, value, callback) => {
      console.log('validateCompany: ' + value)
      if (!value) {
        return callback(new Error(this.$t('register_input_company_empty')));
      }
      if (value.length > 32) {
        return callback(new Error(this.$t('register_input_company_limit_max')));
      }
      if (value.length < 2) {
        return callback(new Error(this.$t('register_input_company_limit_min')));
      }
      callback();
    };

    const validateNickname = (rule, value, callback) => {

      if (!value) {
        return callback(new Error(this.$t('register_input_user')));
      }
      callback();
    };

    const validateMobile = (rule, value, callback) => {
      if (!value) {
        return callback(new Error(this.$t('register_input_mobile_empty')));
      }
      if (value.length > 11) {
        return callback(new Error(this.$t('register_input_mobile_limit_max')));
      }
      if (value.length < 11) {
        return callback(new Error(this.$t('register_input_mobile_limit_min')));
      }
      callback();
    };

    const validatePassword = (rule, value, callback) => {
      if (!value) {
        return callback(new Error(this.$t('register_input_password_empty')));
      }
      var re = /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{6,32}$/;
      if (!re.test(value)) {
        return callback(new Error(this.$t('register_input_password_format_error')));
      }else{
        if(this.form.password && this.form.againPassword && this.form.password != this.form.againPassword){
          callback(new Error('两次输入密码不一致'));
        }else{
          callback();
        }
      }
    };

    const validateCaptcha = (rule, value, callback) => {
      var re = /^\w{4}$/;
      if (!value) {
        return callback(new Error(this.$t('register_input_captcha_empty')));
      }
      if (!re.test(value)) {
        return callback(new Error(this.$t('register_input_captcha_format_error')));
      }
      if (!util.captchaCodeCheck(value,this.captchaHash)) {
        return callback(new Error(this.$t('register_input_captcha_error')));
      }
      callback();
    };
    const validateMobileCode = (rule, value, callback) => {
      if (!value) {
        return callback(new Error(this.$t('register_input_mobile_code')));
      }
      if (!util.captchaCodeCheck(value,this.mobileHash)) {
        return callback(new Error(this.$t('register_input_captcha_error')));
      }
      callback();
    };

    const validateRead = (rule, value, callback) => {
      if (!value) {
        return callback(new Error(this.$t('read_check')));
      }
      callback();
    };

    return {
      // isProduction: api.isProduction,
      lang: Cookies.get('lang') || 'zh-CN',
      userAgent: navigator.userAgent,
      loading: false,
      form: {
        company: '',
        nickname: '',
        mobile: '',
        password: '',
        captcha: '',
        mobileCode:'',
        read: false,
        againPassword:'',
      },
      rules: {
        company: [
          {required:this.isCompany ? true: false,validator: validateCompany, trigger: 'blur'}
        ],
        nickname: [
          {validator: validateNickname, trigger: 'blur'}
        ],
        mobile: [
          {validator: validateMobile, trigger: 'blur'}
        ],
        password: [
          {validator: validatePassword, trigger: 'blur'}
        ],
        againPassword: [
          {validator: validatePassword, trigger: 'blur'}
        ],
        captcha: [
          {validator: validateCaptcha, trigger: 'blur'}
        ],
        mobileCode: [
          {validator: validateMobileCode, trigger: 'blur'}
        ],
        read: [
          {validator: validateRead, trigger: 'blur'}
        ],
      },
      isChrome: true,
      captchaUrl:'',
      captchaKey:'',
      captchaHash:'',
      mobileCodeStatus:true,
      sec:60,
      mobileHash:'',
      //invitationToken:'',
      companyStatus:false,
      //isCompany:false,
      bottomMessage:'',
    };
  },
  props:{
    isWxLogin:{
      default:false,
      type:Boolean,
    },
    wxOpenId:{
      default:'',
      type:String,
    },
    unionid:{
      default:'',
      type:String,
    },
    invitationToken:{
      default:'',
      type:String,
    },
    isCompany:{
      default:false,
      type:Boolean,
    },
    isTeacherWxLogin:{
      default:false,
      type:Boolean,
    },
    appid:{
      default:'',
      type:String,
    }
  },
  created() {
    if (this.invitationToken){
        this.checkInvitationToken();
        this.isCompany = true;
    }

    //自动加载验证码
    this.getCaptcha();
  },
  mounted() {
    //校验是否chrome浏览器
    let nav = window.navigator.userAgent;
    if (nav.includes('Chrome') || nav.includes('chrome')) {
      this.isChrome = true
    } else {
      this.isChrome = false
    }
  },
  methods: {
    //注册-邀请链接-检测租户
    checkInvitationToken(){
      let params = {
        op:'checkInvitationToken',
        invitationToken:this.invitationToken,
      };
      this.api.index.signup(params).then((res)=>{
        this.form.company = res.site.name;
        this.companyStatus = true;
      });
    },
    toLogin() {
      this.$router.push({name: 'login',query:{
          from:this.$route.query.from
        }});
    },
    toRegister(){
      this.$router.push({name: 'register'});
    },
    toRegisterSite(){
      this.$router.push({name: 'registersite'});
    },
    //获取图片验证码
    getCaptcha(){
      let params = {
        regenerate:1
      };
      this.api.index.getCaptcha(params).then((res)=>{
        this.captchaUrl = res.bin;
        this.captchaKey = res.key;
        this.captchaHash = res.hash;
      })
    },
    getMobileCode(){
      if(!this.form.mobile){
        this.$Message.warning(this.$t('login_mobild_user'));
        return;
      }
      if(!this.form.captcha){
        this.$Message.warning(this.$t('login_captcha_tip'));
        return;
      }
      if(!util.captchaCodeCheck(this.form.captcha,this.captchaHash)){
        this.$Message.warning(this.$t('login_captcha_tip'));
        return;
      }
      let data = {
        op:'sendMobile',
        mobile: this.form.mobile,
        verifyCode: this.form.captcha,
        verifyCodeKey:this.captchaKey,
      };
      this.api.index.signup(data).then((res)=>{
        this.mobileCodeStatus = false;
        this.mobileHash = res.hash;
        let timer = setInterval(()=>{
          if(this.sec == 0){
            clearInterval(timer);
            this.sec = 60;
            this.mobileCodeStatus = true;
          }else{
            this.sec--;
          }
        },1000)
      }).catch((err)=>{
        console.log('1111')
        this.mobileCodeStatus = false;
        this.mobileHash = '';
        this.sec = 15;
        let timer = setInterval(()=>{
          if(this.sec == 0){
            clearInterval(timer);
            this.sec = 60;
            this.mobileCodeStatus = true;
          }else{
            this.sec--;
          }
        },1000)
      });

    },
    getCaptchaHash(v) {
      for (var i = v.length - 1, h = 0; i >= 0; --i) {
        h += v.charCodeAt(i);
      }
      return h;
    },
    handleSubmit() {
      if (this.loading) {
        return;
      }

      this.$refs.registerForm.validate((valid) => {
        if (!valid) {
          this.loading = false;
          return;
        }
        this.loading = true;
        console.log(util.pswEncryption(this.form.password),'this.form.password');
        let data = {
          company: this.form.company,
          nickname: this.form.nickname,
          mobile: this.form.mobile,
          password: util.pswEncryption(this.form.password),
          verifyCode: this.form.captcha,
          verifyCodeKey: this.captchaKey,
          mobileCode:this.form.mobileCode
        }
        if(this.invitationToken){
          data.invitationToken = this.invitationToken;
        }else{
          if(!this.isCompany){
            data.joinDefaultSite = 1;
          }else{
            data.joinDefaultSite = 0;
            data.createSite = 1;
          }
        }
        if(this.$route.query.from == 'website'){  //从官网来的 注册为老师
          data.isTeacher = 1;
        }

        this.api.index.signup(data).then((res)=>{
          this.loading = false;
          if(this.isWxLogin || this.isTeacherWxLogin){
            this.$store.commit('login', {
              accessToken: res.access_token,
              userName: this.form.mobile
            });
            this.access_token = res.access_token;

            //是否登录的标志
            // Cookies.set('liteAccessToken', res.access_token);
            let domain = util.getDomain();
            //是否登录的标志
            // Cookies.set('liteAccessToken', res.access_token);
            //添加ai问答token

            Cookies.set(tokenName, res.access_token,{domain});
            util.setCookie('liteLoginAccount', this.form.mobile, 30);

            this.$store.commit('setLoginStatus', true);
            this.$emit('success');
          }else{
            //Cookies.set('liteLoginAccount', this.form.mobile, { expires: 7, path: '/'});

            //this.$Message.success(this.$t('register_finish_to_login'));
            //this.$router.push('/login');

            //是否登录的标志
            // Cookies.set('liteAccessToken', res.access_token);
            let domain = util.getDomain();
            //是否登录的标志
            // Cookies.set('liteAccessToken', res.access_token);
            //添加ai问答token

            Cookies.set(tokenName, res.access_token,{domain});
            util.setCookie('liteLoginAccount', this.form.mobile, 30);

            this.$store.commit('setLoginStatus', true);
            //this.$emit('success');

            this.registerSuc();
          }
        }).catch((err)=>{
          this.loading = false;
        });
      });
    },
    registerSuc(){
      console.log(this.$route,'this.$routethis.$route')
      this.$store.commit('loginSuccessRouter',{form:this.$route.query.from,vm:this,fullPath:this.$route.query.from})
    },
  }
}
</script>

<style scoped lang="scss">
.get-code{
  width: 100%;
  border: 1px solid #3399ff;
  color:#3399ff;
  border-radius: 5px;
  cursor: pointer;
  text-align: center;
}
.get-code-not{
  width: 100%;
  border: 1px solid #c3cbd6;
  color:#c3cbd6;
  border-radius: 5px;
  cursor: pointer;
  text-align: center;
}
.message{
  cursor: pointer;
}
</style>
