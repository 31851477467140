import { render, staticRenderFns } from "./registerForm.vue?vue&type=template&id=49829738&scoped=true&"
import script from "./registerForm.vue?vue&type=script&lang=js&"
export * from "./registerForm.vue?vue&type=script&lang=js&"
import style0 from "./registerForm.vue?vue&type=style&index=0&id=49829738&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "49829738",
  null
  
)

export default component.exports