<style lang="scss">
@import './login.scss';
</style>

<template>
  <div class="register">
    <div class="login-logo">
      <img src="../../assets/images/new_icon/logo.png" style="width: 158px" alt="">
    </div>
    <div v-if="!perfectInfo">
      <div class="register-con">
        <Row class="register-form-wx" v-if="!registerType">
          <Col span="15">
            <div class="register-site-desc">
              <div class="register-tit">一站式数字化认证服务</div>
              <p class="register-desc">协助院校、企业及培训机构搭建高赋能人才发展认证体系，从而加速高校的全面提升人才培养质量。</p>
              <p class="register-desc">阿里云全球培训中心战略合作伙伴，负责阿里云/阿里云1+X认证运营服务交付等业务。</p>
              <p class="register-desc">服务院校1000+、企业500+，累计推动超过3W人次的考试认证落地。</p>
            </div>

          </Col>
          <Col span="9">
            <div class="register-wx">
              <p class="wx-tit">微信扫码注册</p>
              <TeacherWxLogin v-if="!perfectInfo" @wxSuccess="wxSuc" @success="loginSuc" :isCompany="true" ></TeacherWxLogin>
              <p class="wx-tip">打开微信App<br />扫一扫授权完成注册</p>
              <p class="register-form" @click="registerTypeFn">不想扫码了？表单注册></p>
              <p class="have-count">有账户了？<span @click="toLogin">登录</span></p>
            </div>
          </Col>
        </Row>
        <Row v-else>
          <i-col span="18" push="3">
            <div class="register-form-con">
              <p class="form-title-style">欢迎注册</p>
              <RegisterForm :isCompany="isCompany"></RegisterForm>
            </div>
          </i-col>
        </Row>
<!--        <div style="width:100%;">-->
<!--          <p style="width:100%;text-align:center;margin-bottom:25px;color:#7a90a7">{{$t('common_copyright')}}</p>-->
<!--          <div class="use-chrome" v-if="userAgent.indexOf('Chrome') < 0">-->
<!--            {{$t('common_use_chrome') }}-->
<!--          </div>-->
<!--        </div>-->
        <div class="privacy-agreement" v-if="!registerType">授权登录视为您已同意<span>《用户协议及隐私协议》</span></div>
      </div>

    </div>
    <div class="perfect-info" v-else>
      <div class="top" >
        <div class="back">
          <span>重新扫码</span><Button @click="back">&lt; 返回</Button>
        </div>
        <div class="info">
          您已扫码，请完善您的信息!
        </div>
      </div>
      <TeacherWxForm :appid="appid" :wxOpenId="wxOpenId" :unionid="unionid" :isCompany="true" @success="loginSuc"></TeacherWxForm>
    </div>
  </div>
</template>

<script>
import Cookies from 'js-cookie';
import RegisterForm from "@/views/guest/components/registerForm";
import TeacherWxLogin from '@/views/guest/components/teacherWxLogin';
import TeacherWxForm from "@/views/guest/components/teacherWxForm";

export default {
  data () {
    return {
      lang: Cookies.get('lang') || 'zh-CN',
      userAgent: navigator.userAgent,
      isChrome: true,
      isCompany:true,
      registerType:false,
      appid:'',
      wxOpenId:'',
      unionid:'',
      perfectInfo:false
    };
  },
  components:{
    RegisterForm,
    TeacherWxLogin,
    TeacherWxForm
  },
  created() {

  },
  mounted () {
    //校验是否chrome浏览器
    let nav = window.navigator.userAgent;
    if (nav.includes('Chrome') || nav.includes('chrome')){
      this.isChrome = true
    } else {
      this.isChrome = false
    }
  },
  methods: {
    registerTypeFn(){
      this.registerType = true;
    },
    loginSuc(){
      this.$store.commit('loginSuccessRouter',{form:this.$route.query.from,vm:this,fullPath:this.$route.query.from})
    },
    toLogin(){
      console.log(this.$route.query.from,'this.$route.query.from++++++++++')
      this.$router.push({
        path:'/login',
        query:{
          from:this.$route.query.from
        }
      })
    },
    wxSuc(data){  //微信扫码成功 完善信息
      this.perfectInfo = true;
      this.appid = data.appid;
      this.wxOpenId = data.wxOpenId;
      this.unionid = data.unionid;
    },
    back(){
      this.perfectInfo = false;
    }
  }
};
</script>
